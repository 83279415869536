.wavepro-fullpage-error-container {
    max-width: 400px;
    margin: auto;
    display: flex;
    height: calc(100vh - 130px);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -40px;

    .message {
        margin: 12px 0 24px 0;
        text-align: center;
    }

    .button-container {
        display: flex;
        gap: 16px;
    }

    h6 {
        margin: 0 !important;
    }
}