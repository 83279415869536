.header-footer-page-container {
  display: flex;
  flex-direction: column;

  .brand-logo-container {
    position: sticky;
    top: 0px;
    z-index: 5;
    padding: 11px 32px;
    height: 60px;
    border-bottom: 0.5px solid rgb(225, 225, 225);
    background-color: var(--du-color-white);
  }

  .loader {
    height: calc(100vh - 200px);
    .sweet-loading {
      margin: 0;
    }
  }

  .main-content {
    width: 100%;
    min-height: calc(100vh - 60px);
    margin: auto;
    .logo-text {
      h6 {
        margin: 0 0 8px 0;
      }
    }
  }
}
