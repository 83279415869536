/* TODO: Deprecate this file */
@font-face {
  font-family: "NotoSansSemiBold";
  src: url("@styles/fonts/NotoSans/NotoSans-Bold.ttf");
}
@font-face {
  font-family: "NotoSansExtraBold";
  src: url("@styles/fonts/NotoSans/NotoSans-ExtraBold.ttf");
}
@font-face {
  font-family: "NotoSansLight";
  src: url("@styles/fonts/NotoSans/NotoSans-Light.ttf");
}
@font-face {
  font-family: "NotoSansMedium";
  src: url("@styles/fonts/NotoSans/NotoSans-Medium.ttf");
}
@font-face {
  font-family: "NotoSansRegular";
  src: url("@styles/fonts/NotoSans/NotoSans-Regular.ttf");
}
