/* TODO: Deprecate this file */
@font-face {
  font-family: "DiodrumSemiBold";
  src: url("@styles/fonts/Diodrum/Diodrum-Semibold.otf");
}
@font-face {
  font-family: "DiodrumBold";
  src: url("@styles/fonts/Diodrum/Diodrum-Bold.otf");
}
@font-face {
  font-family: "DiodrumRegular";
  src: url("@styles/fonts/Diodrum/Diodrum-Regular.otf");
}
@font-face {
  font-family: "DiodrumLight";
  src: url("@styles/fonts/Diodrum/Diodrum-Light.otf");
}
