.wavepro-miverva-container {
  width: 100%;
  height: 100%;

  .miverva-icon-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;

    color: #fff;
    border: none;

    cursor: pointer;

    button {
      //border-radius: 10px;
      font-size: 20px;
      padding: 10px 20px 8px;
      height: 38px;
    }
  }

  .miverva-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 400px;
    height: 90vh;
    border: 1px solid grey;
    background: white;
    z-index: 9;
    border-radius: 2px;

    &-header {
      display: flex;
      padding: 4px 8px 4px 24px;
      background: var(--du-color-white-pale);
      width: 100%;
      justify-content: space-between;
      align-items: center;


    }
  }
}