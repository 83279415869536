.toast-container {
  .modal-content,
  .modal-dialog {
    width: fit-content;
  }

  .modal-body {
    padding: 8px 12px !important;
  }

  .message-container {
    display: flex;
    align-items: flex-start;

    .icon {
      display: flex;
      margin-top: 2px;
    }

    .message {
      flex: 1;
      font-size: 14px;
      font-family: Diodrum;
      padding: 0 8px;
    }
  }
}

.toast-success {
  .modal-body {
    color: var(--du-color-white) !important;
    background: var(--du-color-green) !important;

    path {
      fill: var(--du-color-white) !important;
      fill-opacity: 1;
    }
  }
}

.toast-error {
  .modal-body {
    color: var(--du-color-white) !important;
    background: var(--du-color-red) !important;

    path {
      fill: var(--du-color-white) !important;
      fill-opacity: 1;
    }
  }
}

.toast-warning {
  .modal-body {
    background: var(--du-color-red) !important;
  }
}

.toast-z-index {
  z-index: 999999 !important; //Higest Index
}
