.uf-diagram-container {
  height: 350px;
  width: 100%;
  display: contents;
}

.uf-diagram-container .blenmode-multiply {
  mix-blend-mode: multiply;
}

.uf-diagram-container .blenmode-overlay {
  mix-blend-mode: overlay;
}

.uf-diagram-container .text-style {
  white-space: pre;
  letter-spacing: 0;
}
.uf-diagram-container .normal {
  font-family: "NotoSans";
}

.uf-diagram-container .bold {
  font-family: "NotoSans";
  font-weight: 600;
}

.uf-diagram-container .font-size-160 {
  font-size: 160;
}

.uf-diagram-container .font-size-110 {
  font-size: 110;
}
