@mixin btn-disabled($bg-color: var(--du-color-grey-light)) {
  color: var(--du-color-grey);
  background-color: $bg-color;
  cursor: not-allowed;
  border: none;
}

@mixin btn-mixin($border-color, $bg-color, $border-size: 1px) {
  color: $border-color;
  border: $border-size solid $border-color;
  background-color: $bg-color;
}

@mixin primary-button-mixin($color, $bg-color, $bg-color-hover) {
  color: $color;
  border: 1px solid $bg-color;
  background-color: $bg-color;
  cursor: pointer;

  &:not(:disabled):hover {
    border-color: $bg-color-hover;
    background-color: $bg-color-hover;
  }

  &:disabled {
    @include btn-disabled;
  }
}

@mixin secondary-btn-mixin($color, $bg-color, $bg-color-hover) {
  color: $color;
  border: 1px solid $color;
  background-color: $bg-color;
  cursor: pointer;

  &:not(:disabled):hover {
    color: $bg-color-hover;
    border: 1px solid $bg-color-hover;
  }

  &:disabled {
    @include btn-disabled;
  }
}

@mixin text-button-mixin($color, $bg-color-hover) {
  color: $color;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:not(:disabled):hover {
    color: $bg-color-hover;
  }

  &:disabled {
    @include btn-disabled(transparent);
  }
}

.wp-btn {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: Diodrum;

  &__contained {
    &--primary {
      @include primary-button-mixin(var(--du-color-white), var(--du-color-primary), var(--du-color-primary-dark));
    }

    &--warning {
      @include primary-button-mixin(var(--du-color-black), var(--du-color-orange), var(--du-color-orange-dark));
    }

    &--danger {
      @include primary-button-mixin(var(--du-color-white), var(--du-color-red), var(--du-color-red-dark));
    }
  }

  &__outlined {
    &--primary {
      @include secondary-btn-mixin(var(--du-color-black), var(--du-color-white), var(--du-color-primary-dark));
    }

    &--warning {
      @include secondary-btn-mixin(var(--du-color-orange), var(--du-color-white), var(--du-color-orange-dark));
    }

    &--danger {
      @include secondary-btn-mixin(var(--du-color-red), var(--du-color-white), var(--du-color-red-dark));
    }
  }

  // Define the mixin for button styles


  &__text {
    &--primary {
      @include text-button-mixin(var(--du-color-black), var(--du-color-primary-dark));
    }

    &--danger {
      @include text-button-mixin(var(--du-color-red), var(--du-color-red-dark));
    }

    &--warning {
      @include text-button-mixin(var(--du-color-orange), var(--du-color-warning-dark));
    }
  }

  &--small {
    height: 26px;
    font-size: 12px;
    padding: 7px 10px 5px;
    border-radius: 20px;
  }

  &--medium {
    height: 32px;
    font-size: 14px;
    padding: 10px 24px 7px;
    border-radius: 20px;
  }

  &--large {
    height: 52px;
    font-size: 16px;
    padding: 16px 48px 12px;
    letter-spacing: -0.08px;
    border-radius: 50px;
  }
}