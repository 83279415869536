/* 
font weight mapping is as per UX guidelines ,

300 - light
400 || normal - regular
500 - medium
600 - semi bold
700 || bold - bold
800 - extra bold 
*/
@font-face {
  font-family: "NotoSans";
  font-weight: 300;
  src:
    url("@styles/fonts/NotoSans/NotoSans-Light.woff2") format("woff2"),
    url("@styles/fonts/NotoSans/NotoSans-Light.woff") format("woff"),
    url("@styles/fonts/NotoSans/NotoSans-Light.ttf");
}

@font-face {
  font-family: "NotoSans";
  src:
    url("@styles/fonts/NotoSans/NotoSans-Regular.woff2") format("woff2"),
    url("@styles/fonts/NotoSans/NotoSans-Regular.woff") format("woff"),
    url("@styles/fonts/NotoSans/NotoSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "NotoSans";
  font-weight: 500;
  src:
    url("@styles/fonts/NotoSans/NotoSans-Medium.woff2") format("woff2"),
    url("@styles/fonts/NotoSans/NotoSans-Medium.woff") format("woff"),
    url("@styles/fonts/NotoSans/NotoSans-Medium.ttf");
}

@font-face {
  font-family: "NotoSans";
  font-weight: 600;
  src:
    url("@styles/fonts/NotoSans/NotoSans-SemiBold.woff2") format("woff2"),
    url("@styles/fonts/NotoSans/NotoSans-SemiBold.woff") format("woff"),
    url("@styles/fonts/NotoSans/NotoSans-SemiBold.ttf");
}

@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  src:
    url("@styles/fonts/NotoSans/NotoSans-Bold.woff2") format("woff2"),
    url("@styles/fonts/NotoSans/NotoSans-Bold.woff") format("woff"),
    url("@styles/fonts/NotoSans/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "NotoSans";
  font-weight: 800;
  src:
    url("@styles/fonts/NotoSans/NotoSans-ExtraBold.woff2") format("woff2"),
    url("@styles/fonts/NotoSans/NotoSans-ExtraBold.woff") format("woff"),
    url("@styles/fonts/NotoSans/NotoSans-ExtraBold.ttf");
}

/* 
300 - light
400 - regular
500 - medium
600 - semi bold
700 - bold
 */
@font-face {
  font-family: "Diodrum";
  src:
    url("@styles/fonts/Diodrum/Diodrum-Light.woff2") format("woff2"),
    url("@styles/fonts/Diodrum/Diodrum-Light.woff") format("woff"),
    url("@styles/fonts/Diodrum/Diodrum-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Diodrum";
  src:
    url("@styles/fonts/Diodrum/Diodrum-Regular.woff2") format("woff2"),
    url("@styles/fonts/Diodrum/Diodrum-Regular.woff") format("woff"),
    url("@styles/fonts/Diodrum/Diodrum-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Diodrum";
  src:
    url("@styles/fonts/Diodrum/Diodrum-Semibold.woff2") format("woff2"),
    url("@styles/fonts/Diodrum/Diodrum-Semibold.woff") format("woff"),
    url("@styles/fonts/Diodrum/Diodrum-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Diodrum";
  src:
    url("@styles/fonts/Diodrum/Diodrum-Bold.woff2") format("woff2"),
    url("@styles/fonts/Diodrum/Diodrum-Bold.woff") format("woff"),
    url("@styles/fonts/Diodrum/Diodrum-Bold.otf");
  font-weight: 700;
}
