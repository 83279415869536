.wp-sidenav-wrapper {
  display: block;
  width: 35%;
  max-width: 270px;
  position: sticky;
  background-color: var(--du-color-white);
  overflow-y: auto;
  border: solid var(--du-color-grey-light) 0.5px;

  @media (max-width: 1024px) {
    width: 50%;
  }
}

.wp-sidenav-overlay {
  background-color: var(--du-color-black_85);
  position: fixed;
  z-index: 9;
  top: 60px;
  width: 100%;
  height: 100%;
}
