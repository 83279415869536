$footer-text-color: var(--du-color-primary-light);

.footer-wrapper {
  padding: 20px 30px;
  background-color: var(--du-color-primary-aquamarine);

  .footer-link {
    font-size: 10px;
    font-family: "Diodrum";
    color: $footer-text-color;
    text-decoration: none;
  }

  .footer-links-wrapper {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .copy-right-text {
    font-size: 11px; // TOD0 : 11 is odd check with UX??
    color: var(--du-color-white);
  }

  .vertical-line {
    display: inline-block;
    height: 12px;
    border-left: 2px solid var(--du-color-white);
    margin: 0px 10px;
  }

  .social-icons {
    display: inline-flex;
    width: 96px;
    height: 32px;
    margin-left: -6px;
  }

  img {
    margin-left: 16px;
    width: 72px;
  }

  .footer-left-content {
    width: 55%;
  }

  .footer-right-content {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .wave-engine-text {
    font-size: 16px;
    font-family: "Diodrum";
    font-weight: 600;
    color: var(--du-color-white);
  }

  .dupont-solutions-text {
    font-size: 12px;
    font-family: "Diodrum";
    color: var(--du-color-white);
  }

  .version-text {
    font-size: 11px;
    color: $footer-text-color;
  }
}
